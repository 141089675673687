import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import React from 'react';
import '../assets/stylesheets/pages/blog-post.scss';
import ByLine from '../components/ByLine/ByLine';
import SEO from '../components/SEO/SEO';

export default function BlogPost({ data }) {
  const {
    markdownRemark: { html, frontmatter },
  } = data;

  return (
    <>
      <SEO
        title={`${frontmatter.headline} ${frontmatter.subhead}`}
        // strip off the leading '/'
        image={frontmatter.image.childImageSharp.fixed.src.substring(1)}
      />
      <div className="blog-container">
        <ByLine
          className="blog-container__by-line"
          author={frontmatter.author}
          publishDate={frontmatter.publishDate}
        />
        <div className="blog-content">
          <h1>{frontmatter.headline}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </>
  );
}

BlogPost.propTypes = {
  data: PropTypes.any,
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        publishDate(formatString: "MMMM DD, YYYY")
        slug
        headline
        subhead
        author
        image {
          childImageSharp {
            fixed(width: 1440) {
              src
            }
          }
        }
      }
    }
  }
`;
